<template>
  <b-card
    v-if="tableData"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="tableData"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- company -->
      <template #cell(companyName)="data">
        <div class="d-flex align-items-center">
          <b-avatar
            rounded
            size="32"
            variant="light-company"
            :src="getAvatarUrl(data.item.companyLogo)"
          >
           </b-avatar>
          <div>
            <div class="font-weight-bolder">
              {{ data.item.companyName }}
            </div>
           
          </div>
        </div>
      </template>

       <template #cell(percent)="data">
        <b-progress
          :value="data.item.percent"
          max="100"
          height="7px"
          :variant="data.item.percent>70?'danger':(data.item.percent>50?'warning':'success')"
          striped
        />
      </template>

      <!-- category -->
      <template #cell(users)="data">
         <b-avatar-group  class="mt-2 pt-50" size="33">
              <b-avatar
              v-for="avatar in data.item.users"
              :key="avatar.avatarFileUrl"
              size="30"
               v-b-tooltip.hover.top="avatar.userFullName"
              :src="avatar.avatarFileUrl ? (avatar.avatarFileUrl.startsWith('data:image') ? avatar.avatarFileUrl : `data:image/jpeg;base64,${avatar.avatarFileUrl}`) : ''"

              class="pull-up"
            />
            </b-avatar-group>
      </template>

    
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg,BAvatarGroup,VBTooltip,BProgress
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BAvatarGroup,
    BProgress,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
directives: {
    
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      fields: [
        { key: 'companyName', label: 'COMPANY' },
        { key: 'title', label: 'Position' },
        { key: 'percent', label: 'Position Time' },
        { key: 'total', label: 'Candidates' },
        { key: 'users', label: 'Recruiters' },
      ],
    }
  },

  methods: {
  
   getAvatarUrl(Url){
    return  process.env.VUE_APP_BASE_URL + Url;
   },
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
